:root {
  --white: #fff;
  --black: #000;
  --ebony-clay: #161B1F;
  --ebony-clay-light: #385368;
  --dark-ebony-clay: #1E292F;
  --primary: #02734f;
  --primary-disabled: #0d402f;
  --primary-hover: #26bf8e;
  --primary-weak: #3a8e73;
  --light-gray: #252937;
  --dark-gray: #171E24;
  --red: #F55755;
  --mirage: #192732;
  --green: #33FF89;
  --violet: #333aa0;
  --dark-blue: #030b18;
}

.c {
  &-lg {
    color: var(--light-gray);
  }

  &-w {
    color: var(--white);
  }

  &-b {
    color: var(--black);
  }

  &-eb {
    color: var(--ebony-clay);
  }

  &-dec {
    color: var(--dark-ebony-clay);
  }

  &-p {
    color: var(--primary);
  }

  &-pd {
    color: var(--primary-disabled);
  }

  &-ph {
    color: var(--primary-hover);
  }

  &-pw {
    color: var(--primary-weak);
  }

  &-dg {
    color: var(--dark-gray);
  }

  &-r {
    color: var(--red);
  }

  &-m {
    color: var(--mirage);
  }

  &-g {
    color: var(--green);
  }
}

.bg {
  &-t {
    background: transparent;
  }

  &-p {
    background-color: var(--primary);
  }

  &-lg {
    background-color: var(--light-gray);
  }

  &-dg {
    background-color: var(--dark-gray);
  }

  &-ec {
    background-color: var(--ebony-clay);
  }

  &-ecl {
    background-color: var(--ebony-clay-light) !important;
  }


  &-pw {
    background-color: var(--primary-weak);
  }

  &-v {
    background-color: var(--violet);
  }
}