.popular-pair {
    padding: 4px 8px;
    border-radius: 4px;
    background-color: var(--dark-ebony-clay);
    border: 1px solid #1a1a1b;
    width: fit-content;
    cursor: pointer;
    line-height: 14px;

    &__logo {
        margin-right: 5px;
    }

    &__plus {
        margin: 0 5px;
    }

    &:hover {
        background-color: #212933;
    }

}

.pair-list {

    display: flex;
    flex-wrap: wrap;

    &-wrapper {
        border-left: 1px solid rgb(85, 112, 131);
    }

    &-item {
        font-size: 15px;

        &:not(:last-of-type) {
            margin-right: 10px;
            margin-bottom: 10px;
        }
    }
}


@media screen and (max-width: 960px) {
    .pair-list {

        flex-wrap: nowrap;
        overflow: auto;
        margin-left: -1rem;
        margin-right: -1rem;
        padding-left: 1rem;
        padding-right: 1rem;

        &-wrapper {
            border: none;
        }
    }
  
  }
  
  @media screen and (max-width: 720px) {
  
  }
  
  @media screen and (max-width: 500px) {
  .popular-pair {
      padding: 11px 8px;
  }
  }