.card-wrapper {
  position: relative;
  background-color: rgba(19, 24, 27, 0.7);
  // backdrop-filter: blur(6px);
}
.dark {
  background-color: var(--dark-ebony-clay);
}
.light {
  background-color: var(--ebony-clay);
}