@media (max-width: 500px) {
    .app-body {
        padding-top: 5rem !important;
    }
}   

.swap-card {
    background: none;
}

.dropdown {
    display: flex;
    position: relative;
    cursor: pointer;
    align-items: center;
    width: 200px;
}

.dropdown-open {
    position: absolute;
    top: 30px;
    background: #383838;
    z-index: 99;
    border-radius: 14px;
    display: flex;
    color: white;
    flex-direction: column;
    justify-content: start;

    &:hover {
        color: #008d5f;
        .subtitle {
            color: #008d5f;
        }
    }


    .subtitle {
        color: grey;
        font-size: 10px;
        margin-top: 5px;
    }
}